import React, { useEffect} from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading, Text  } from 'grommet';
import { useQuizAppState } from "../QuizAppContext";

export function DigitalTransformationStep() {
  const {
    state: { digitalTransformationScore },
    dispatch,
  } = useQuizAppState();

  const isValid = digitalTransformationScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'quizapp-step-digitaltransformation' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [digitalTransformationScore])

  return (
    <>
      <Heading level="2" color="#ffb600">4. Digital Transformation</Heading>
      <Heading level="4" margin={{ vertical: '30px' }}>Evaluate the following statement:</Heading>
      <Box direction="row-responsive" justify="between">
        <Box basis='45%'>
          <Box>
            <Paragraph style={{ maxWidth: 'inherit' }}>We have invested in new technologies and have gotten value, but the returns are less than we expected and difficult to measure.</Paragraph>
            <Form style={{ margin: '20px 0', maxWidth: 'inherit' }}>
              <RadioButtonGroup
                style={{lineHeight: '1.8'}}
                name="digitalTransformationScore"
                options={[
                  { label: 'Yes, this is mostly true and our digital transformation efforts have not met our expectations.', value: '1', id: 'one' },
                  { label: 'This is partially true; we’re mostly satisfied with our digital transformation, but there is always room for improvement.', value: '3', id: 'two' },
                  { label: 'No, our digital transformation efforts have been extremely successful.', value: '5', id: 'three' }
                ]}
                gap="medium"
                value={digitalTransformationScore}
                onChange={(event) => {
                  dispatch({ type: "DIGITALTRANSFORMATION_SCORE_CHANGE", payload: event.target.value  })
                }}
              />
              </Form>
            </Box>
          </Box>
          <Box basis='45%'>
            <Box 
              style={{
              padding: '30px',
              border: '6px solid #ffb600',
              opacity: digitalTransformationScore !== '' ? 1 : 0,
              transition: 'opacity 400ms'
              }}>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', paddingBottom:'20px',maxWidth: '600px',}}>Many companies have struggled with digital transformation, and have found that investment in technology is typically not enough to improve performance or productivity across departments.</Paragraph>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', maxWidth: '600px',paddingBottom:'20px'}}><strong>Technology by itself is not the answer</strong> if there is no upskilling to support new implementations. Technology platforms typically offer free training on how to use them, but often fall short in the “why” and the “when” as incentives to help their people embrace change.</Paragraph>
            </Box>
        </Box>
      </Box>
    </>
  );
}

export default DigitalTransformationStep;
