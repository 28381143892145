import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Box, Heading, Image, Paragraph, Text, ThemeContext } from 'grommet';
import { withPrefix } from 'gatsby';
import { useQuizAppState } from "../QuizAppContext";
import QuizForm from "../ui/Form";
import { useCookie } from 'react-use';

const NextButton = styled(Button)`
  background: #D04A02;
  border: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 120px;
`;

const BackButton = styled(Button)`
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  border-radius: 0;
  margin-right: 20px;
  max-width: 120px;
`;

const FormContainer = styled(Box)`
  margin-top: 20px;
  padding: 10px 40px 0;
  border: 4px solid #ffffff;
`;

export function ResultOfferStep({  formSection, goBack, goForward, overallCalc }) {
  const {
    state,
    dispatch,
  } = useQuizAppState();

  useEffect(() => { 
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'quizapp-step-ebookoffer-form' });
    // dispatch({ type: "SHOULD_ALLOW_NEXT", payload: true });
  }, [])

  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);
  
  const handleFormSubmitSuccess = () => {
    goForward();
    updateCookie(COOKIE_VALUE_UNGATED);
  }

  // const RedirectURL = () => {
  //   updateCookie(COOKIE_VALUE_UNGATED);
  //   if (typeof window !== 'undefined') {
  //     window.location = '/enterprise-upskilling-quiz-app-results';
  //   }
  //   return null;
  // }

  return (
    <>
      <Heading level="2" color="#ffb600">Want to see your results?</Heading>
      <Box direction="row-responsive" justify="between">
        <Box basis='45%'>
        <Heading level="4" margin={{ vertical: '30px'}} style={{maxWidth: 'inherit'}}>Fill out the form to access your results.</Heading>
          <Box>
            <Paragraph style={{ maxWidth: 'inherit', paddingBottom: '20px' }}>We’ll also give you a copy of our eBook:</Paragraph>
            <Heading level="3" style={{fontFamily: 'PwC ITC Charter, serif', fontWeight: '400', marginBottom: '40px' }}>"Digital upskilling at scale: Aligning skills and culture with the changing nature of work." </Heading>
            <Image style={{maxWidth: '320px'}} src="https://images.ctfassets.net/fwgo2d14oeir/38H6KRCoMjP7fMtgAfZRLp/6c5d67a0b7fdc5106f9a7f22786bb41b/ebook-digital-upskillingv2.png" />
          </Box>
        </Box>
        <Box basis='48%'>
          <FormContainer>
            <QuizForm formSection={formSection} onSubmitSuccess={() => handleFormSubmitSuccess()} />
          </FormContainer>
        </Box>
      </Box>
    </>
  );
}


export default ResultOfferStep;
