import React from 'react';
import PropTypes from 'prop-types';
import { useField, Field } from 'formik';
import Carat from '../../../assets/svg/carat.svg';
import Checkmark from './checkmark.svg';
import slugify from 'slugify';

const FieldFactory = (props) => {
  const { type } = props;

  switch (type) {
    case 'inline-radio':
      return <RadioGroupInput layout={'inline'} {...props} />;
    case 'radio':
      return <RadioGroupInput {...props} />;
    case 'textarea':
      return <TextAreaInput {...props} />;
    case 'select':
      return <SelectInput {...props} />;
    case 'hidden':
      return <HiddenInput {...props} />;
    case 'text':
    case 'tel':
    case 'email':
    default:
      return <TextInput {...props} />;
  }
};

const HiddenInput = ({ value, name }) => {
  return <input type="hidden" name={name} value={value} />;
};

const ErrorMessage = ({ showError, children }) => {
  return (
    <div className="input-group__error">
      {showError && (
        <div className="input-group__error-message">{children}</div>
      )}
    </div>
  );
};

const InputGroup = ({ className, children, disabled, size, ...props }) => {
  // wrap up label and input to provide state classes to both.

  const { touched, error, value, type } = props;
  const valueState = `input-group--${value ? 'hasValue' : 'isEmpty'}`;
  const touchedState = `input-group--${touched ? 'hasTouched' : 'notTouched'}`;
  const errorState = `input-group--${error ? 'hasError' : 'noError'}`;
  const disabledState = `input-group--${disabled ? 'disabled' : 'enabled'}`;
  const sizeClass = `input-group--${size}`;

  let inputGroupClasses = `input-group input-group--${type} ${valueState} ${touchedState} ${errorState} ${disabledState} ${sizeClass} ${
    className ? className : ''
  }`;

  return <div className={inputGroupClasses}>{children}</div>;
};

InputGroup.propTypes = {
  size: PropTypes.oneOf(['full', 'half', 'small', 'medium']),
};
InputGroup.defaultProps = {
  size: 'half',
};

const SelectInput = ({
  className,
  label,
  placeholder,
  options,
  disabled,
  size,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <InputGroup
      {...meta}
      {...field}
      size={size}
      disabled={disabled}
      type={'select'}
      className={`${props.name}-input`}
    >
      <label htmlFor={props.id || props.name} className={'input__label'}>
        {label}
      </label>

      <div className="input-group--select__wrapper">
        <select disabled={disabled} className="input input--select" {...field}>
          {/* Unselectable first option */}
          <option disabled={true} value={''}>
            {placeholder}
          </option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          })}
        </select>
        <Carat />
      </div>

      <ErrorMessage showError={meta.touched && meta.error}>
        {meta.error}
      </ErrorMessage>
    </InputGroup>
  );
};

SelectInput.defaultProps = {
  placeholder: 'Choose one',
};

const TextInput = ({
  className,
  size,
  label,
  disabled,
  placeholder,
  ...props
}) => {
  let inputClasses = `input input--${props.type}`;
  let labelClasses = 'input__label';
  const [field, meta] = useField(props);

  return (
    <InputGroup size={size} {...meta} {...field} type={props.type}>
      <label htmlFor={props.id || props.name} className={labelClasses}>
        {label}
      </label>
      <input
        className={inputClasses}
        type={props.type}
        {...field}
        placeholder={placeholder}
        disabled={disabled}
      />
      <ErrorMessage showError={meta.touched && meta.error}>
        {meta.error}
      </ErrorMessage>
    </InputGroup>
  );
};

const RadioGroup = ({ options, ...props }) => {
  return (
    <div className="radio-groups">
      {options.map((option = '', index) => {
        const labelInputId = `${props.name}--${index}`;
        return (
          <div key={index} className="radio-group">
            <Field
              className={'input--radio'}
              id={labelInputId}
              type={'radio'}
              name={props.name}
              value={option}
            />
            <label key={index} htmlFor={labelInputId}>
              {option}
            </label>
          </div>
        );
      })}
    </div>
  );
};
RadioGroup.defaultProps = {
  options: ['Yes', 'No'],
};

const RadioGroupInput = ({
  layout,
  className,
  label,
  size,
  options,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'radio' });
  return (
    <InputGroup
      {...meta}
      {...field}
      size={size}
      type={'radio'}
      className={`input-group--radio--${layout}`}
    >
      <div className="radio-group__title">{label}</div>
      <div className="radio-group__inputs">
        <RadioGroup {...field} options={options} />
      </div>
      <ErrorMessage showError={meta.touched && meta.error}>
        {meta.error}
      </ErrorMessage>
    </InputGroup>
  );
};

RadioGroupInput.defaultProps = {
  layout: 'stacked',
};
RadioGroupInput.propTypes = {
  layout: PropTypes.oneOf(['stacked', 'inline']),
};

const CheckboxInput = ({ className, size, label, ...props }) => {
  let inputClasses = `input input--checkbox`;
  let labelClasses = 'input__label';
  const [field, meta] = useField(props);

  const checkboxStyle = {
    backgroundImage: field.value ? `url('${Checkmark}')` : null,
  };

  return (
    <InputGroup size={size} {...meta} {...field} type={'checkbox'}>
      <div className="checkbox-group">
        <label htmlFor={props.id || props.name} className={labelClasses}>
          {label}
        </label>
        <input
          id={props.id || props.name}
          style={checkboxStyle}
          className={inputClasses}
          type={'checkbox'}
          {...field}
          {...props}
        />
      </div>
      <ErrorMessage showError={meta.touched && meta.error}>
        {meta.error}
      </ErrorMessage>
    </InputGroup>
  );
};

const TextAreaInput = ({ className, size, label, placeholder, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'textarea' });
  return (
    <InputGroup
      size={size}
      className={className}
      {...meta}
      {...field}
      type={`textarea`}
    >
      <label className={`input__label`} htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea
        className={'input input--textarea'}
        placeholder={placeholder}
        {...field}
      />
      <ErrorMessage showError={meta.touched && meta.error}>
        {meta.error}
      </ErrorMessage>
    </InputGroup>
  );
};

export {
  TextInput,
  TextAreaInput,
  SelectInput,
  CheckboxInput,
  RadioGroupInput,
  HiddenInput,
  FieldFactory,
};
