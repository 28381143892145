import { graphql } from 'gatsby';
import React, { useState } from "react";
import styled from "styled-components";
import { Box, Collapsible, Heading, Grommet } from 'grommet';
import { Info } from 'grommet-icons';
import LayoutApp from '../components/LayoutApp';
import QuizApp from "../apps/upskill-quiz/QuizApp";
import { QuizAppProvider } from "../apps/upskill-quiz/QuizAppContext";
import SiteMeta from '../components/SiteMeta';
import { proedgeTheme } from '../grommetui/grommet/Theme';

const metaTitle = 'Enterprise Upskilling Quiz | PwC ProEdge';
const metaDesc = 'How do you know if upskilling is an investment you should prioritize? Is your company on a path to generate value from digital skills development? Answer these six questions to find out where you stand.';

export default function({ data }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [show, setShow] = useState();
  const {
    headerLogo,
    site: { siteMetadata },
    contentfulQuizPage,
  } = data;
  
  return (
    <LayoutApp headerLogo={headerLogo} navMenu={siteMetadata.navMenu}>
      <SiteMeta title={metaTitle} description={metaDesc} />
        <Grommet theme={proedgeTheme} style={{ backgroundImage:'url(/img/quiz-hero-bg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}} background='dark-5' >
          <Box style={{ backgroundColor: 'rgba(0,0,0,.9)' }}>
          <QuizAppProvider>
              <QuizApp data={{ formSection: contentfulQuizPage.formSection }} />
          </QuizAppProvider>
        </Box>
      </Grommet>
    </LayoutApp>
  );
};

export const query = graphql`
  query {
    headerLogo: file(relativePath: { eq: "logo-gray.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        navMenu {
          title
          href
          items {
            description
            href
            title
          }
        }
      }
    }
    contentfulQuizPage {
      formSection {
        ...Form
      }
    }
  }
`;
