import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Anchor, Button, Box, Heading, Paragraph, Text } from 'grommet';
import { useQuizAppState } from '../QuizAppContext';
import { ScoreExplain } from '../ui/ScoreExplain';

const NextButton = styled(Button)`
  background: #ea8c00;
  border-width: 2px;
  border-style: solid;
  border-color: #ea8c00;
  color: #000000;
  max-width: 280px;
  padding: 8px 16px;
  border-radius: 6px;
`;

const ScoreContainer = styled(Box)`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 8px solid #d2d2d2;
  padding: 40px;
`;

const ScoreHeading = styled(Heading)`
  padding: 0;
  margin: 0 0 30px 0;
`;

const ScoreTextContainer = styled(Box)`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 8px solid #d2d2d2;
  padding: 40px;
`;

const ScoreText = styled(Text)`
  padding: 0;
  font-size: 24px;
  font-family: 'PwC ITC Charter, serif';
`;

export function ResultBreakdownStep({ isSmall, overallCalc }) {
  const { state, dispatch } = useQuizAppState();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer)
      window.dataLayer.push({
        event: 'quizapp-step-resultbreakdown-score',
      });
  }, []);

  return (
    <>
    <Heading level="2" color="#ffb600">
        Great! Here's how you stack up.
    </Heading>
    <Heading level="4" margin={{ vertical: '30px' }} style={{maxWidth: ' 960px'}}>
        Also, here is a link to the eBook: <Anchor href="https://assets.ctfassets.net/fwgo2d14oeir/3dodoSi1wuIPO7rZNfD4cq/63a93e29d96ed0819aca73569cc8387a/PwC_ProEdge_eBook_DigitalUpskillingAtScale.pdf" target="_blank" style={{textDecoration: 'none', paddingBottom: '1px', borderBottom: '1px dotted #D04A02'}}>Digital upskilling at scale</Anchor>.
    </Heading>
    <Box direction="column"  justify="between">
        <Paragraph style={{marginTop: '20px', maxWidth: '100%'}}>
            <Text style={{backgroundColor: '#FFFFFF', padding: '8px 20px', color: '#000000', display: 'block'}}>Your enterprise upskilling needs assessment score:</Text>
        </Paragraph>
        <ScoreExplain totalScore={overallCalc} />
    </Box>
    </>
  );
}

export default ResultBreakdownStep;
