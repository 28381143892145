import React, { useEffect, useState } from "react";
import { Form, Box, Button, RadioButtonGroup, Paragraph, Heading} from 'grommet';
import { useQuizAppState } from "../QuizAppContext";

export function DisruptionStep() {
  const {
    state: { disruptionScore },
    dispatch,
  } = useQuizAppState();
  const [value, setValue] = useState();
  const isValid = disruptionScore !== '';
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'quizapp-step-disruption' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [disruptionScore])
  
  return (
    <>
      <Heading level="2" color="#ffb600">1. Disruption</Heading>
      <Heading level="3" margin={{ vertical: '30px' }}>Evaluate the following statement:</Heading>
      <Box direction="row-responsive" justify="between">
        <Box basis='45%'>
          <Box>
            <Paragraph style={{ maxWidth: 'inherit' }}>With COVID-19, adapting to new ways of work was a challenge. </Paragraph>
            <Paragraph style={{ margin: '10px 0', maxWidth: 'inherit' }}>Productivity, collaboration and new technologies all proved difficult to master and measure. While we made it through, I have concerns about our corporate readiness for future disruptions.</Paragraph>
            <Form style={{ margin: '20px 0', maxWidth: 'inherit' }}>
                <RadioButtonGroup
                  style={{lineHeight: '1.8'}}
                  name="disruptionScore"
                  options={[
                    { label: 'Yes, this is mostly true and readiness in the face of disruption is a concern.', value: '1', id: 'one' },
                    { label: 'This is partially true; we had some difficulties but feel comfortable with our future posture.', value: '3', id: 'two' },
                    { label: 'No, we had no issues handling COVID-19 and feel secure about facing the next challenge.', value: '5', id: 'three' }
                  ]}
                  value={disruptionScore}
                  gap="medium"
                  onChange={(event) => {
                    const value = event.target.value;
                    dispatch({ type: "DISRUPTION_SCORE_CHANGE", payload: value })
                  }}
                />
              </Form>
            </Box>
          </Box>
          <Box basis='45%'>
            <Box
              style={{
              padding: '30px',
              border: '6px solid #ffb600',
              opacity: disruptionScore !== '' ? 1 : 0,
              transition: 'opacity 400ms'
              }}>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', paddingBottom:'20px',maxWidth: '600px',}}>Corporate response to COVID-19 placed a spotlight on technology and productivity across a remote workforce. While online meeting and collaboration tools were in the spotlight, adaptability and the ability to work differently became essential skills.</Paragraph>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', maxWidth: '600px',paddingBottom:'20px'}}>While some workers adapted well to disruption, others struggled with the change and may have benefited from training or coursework focused on digital upskilling.</Paragraph>
            </Box>
        </Box>
      </Box>
    </>
  );
}

export default DisruptionStep;
