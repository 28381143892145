import React, { useState } from 'react';
import styled from 'styled-components';
import { Anchor, Button, Box, Paragraph, Heading, Image, ResponsiveContext, ThemeContext } from 'grommet';
import {
  DisruptionStep,
  WorkforceTransformationStep,
  CorporateCultureStep,
  DigitalTransformationStep,
  MultiGenWorkforceStep,
  UpskillingStep,
  ResultOfferStep,
  ResultBreakdownStep,
} from './steps';
import { useQuizAppState } from './QuizAppContext';
import { useCookie } from 'react-use';

function useFormProgress() {
  const [currentStep, setCurrentStep] = useState(0);

  function goForward() {
    setCurrentStep(currentStep + 1);
  }

  function goBack() {
    setCurrentStep(currentStep - 1);
  }
  return [currentStep, goForward, goBack];
}

const BackButton = styled(Button)`
  border: 1px solid #d2d2d2;
  color: #d2d2d2;
  border-radius: 0;
  margin-right: 20px;
`;

const NextButton = styled(Button)`
  border: 1px solid #d04a02;
  background: #d04a02;
  border: 0;
  border-radius: 0;
`;

const background = {
  color: '#000000',
};

const FooterContainer = styled(Box).attrs(({ background }) => ({
  background,
  direction: 'row',
  justify: 'center',
}))``;

function QuizApp({ data }) {
  const { formSection } = data;
  const { dispatch, state } = useQuizAppState();
  const [currentStep, goForward, goBack] = useFormProgress();

  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);
 
  const {
    disruptionScore,
    workforceScore,
    digitalTransformationScore,
    corporateCultureScore,
    upskillingScore,
    multiGenScore,
  } = state;
  const disruptionRealScore = /5-.*/.test(disruptionScore)
    ? 5
    : parseInt(disruptionScore, 10);
  const workforceRealScore = /5-.*/.test(workforceScore)
    ? 5
    : parseInt(workforceScore, 10);
  const digitalTransformationRealScore = /5-.*/.test(digitalTransformationScore)
    ? 5
    : parseInt(digitalTransformationScore, 10);
  const corporateCultureRealScore = /5-.*/.test(corporateCultureScore)
    ? 5
    : parseInt(corporateCultureScore, 10);
  const upskillingRealScore = /5-.*/.test(upskillingScore)
    ? 5
    : parseInt(upskillingScore, 10);
  const multiGenRealScore = /5-.*/.test(multiGenScore)
    ? 5
    : parseInt(multiGenScore, 10);

  const overallCalc =
    disruptionRealScore +
    workforceRealScore +
    digitalTransformationRealScore +
    corporateCultureRealScore +
    upskillingRealScore +
    multiGenRealScore;

  const controlFunctions = {
    goForward,
    goBack,
  };

  const steps = [
    <DisruptionStep {...controlFunctions} />,
    <WorkforceTransformationStep {...controlFunctions} />,
    <CorporateCultureStep {...controlFunctions} />,
    <DigitalTransformationStep {...controlFunctions} />,
    <MultiGenWorkforceStep {...controlFunctions} />,
    <UpskillingStep {...controlFunctions} />,
    <ResultOfferStep
      {...controlFunctions}
      formSection={formSection}
      overallCalc={overallCalc}
    />,
    <ResultBreakdownStep overallCalc={overallCalc} {...controlFunctions} />,
  ];

  const stepsVIP = [
    <DisruptionStep {...controlFunctions} />,
    <WorkforceTransformationStep {...controlFunctions} />,
    <CorporateCultureStep {...controlFunctions} />,
    <DigitalTransformationStep {...controlFunctions} />,
    <MultiGenWorkforceStep {...controlFunctions} />,
    <UpskillingStep {...controlFunctions} />,
    <ResultBreakdownStep overallCalc={overallCalc} {...controlFunctions} />,
  ];

  const isFirst = currentStep === 0;
  const isLast = currentStep === steps.length - 2;
  const isResultStep = currentStep === steps.length - 1;
  const isLastVIP = currentStep === stepsVIP.length - 2;
  const isResultStepVIP = currentStep === stepsVIP.length - 1;
  const isVIP = value === COOKIE_VALUE_UNGATED;
  return (
    <>
    <ResponsiveContext.Consumer>
        {(size) => {
        const isSmall = size === 'small';
        const isXlarge = size === 'xlarge';
        return (
            <>
            <Box
              pad = {{ horizontal: isSmall ? '40px' : isXlarge ? '120px' : '80px', vertical: isSmall ? '40px' : '80px'}}
            >
                <Box style={{ margin: isXlarge ? '0 auto' : '0 auto', maxWidth: '1200px' }}>
                {isVIP ? stepsVIP[currentStep] : steps[currentStep]}
                {isResultStep ? null : isLast ? null : (
                <>
                    <Box
                    direction="row"
                    align="center"
                    justify="start"
                    style={{
                        marginTop: '20px',
                        maxWidth: '280px'
                    }}
                    >
                    {!isFirst && <BackButton label="Back" onClick={() => goBack()} />}
                    <NextButton
                        label={isLastVIP && isVIP ? 'Get score' : 'Next'}
                        type="submit"
                        disabled={!state.shouldAllowNext}
                        onClick={(e) => {
                        e.preventDefault();
                        goForward();
                        }}
                    />
                    </Box>
                </>
                )}
                </Box>
            </Box>
        {isResultStep || (isVIP && isResultStepVIP) ? (
          <FooterContainer background={background}>
            <Box
              pad = {{ horizontal: isSmall ? '40px' : '120px', vertical: isSmall ? '40px' : '80px'}}
              style={{ backgroundColor: 'rgba(0,0,0,.7)', margin: isXlarge ? '0 88px' : 'inherit', maxWidth: !isSmall ? '1440' : 'inherit' }}
            >
              <Image
                style={{ width: '80px' }}
                src="https://images.ctfassets.net/fwgo2d14oeir/5K1ySiET3U3frWRZg4031g/2e79e50b4e490f096847191c9d87ee6e/PwC_Pictograms_Quality-Recognition_White_RGB.svg"
              />
              <Heading
                level="2"
                margin={{vertical: '30px'}}
              >
                Future-proof your workforce with ProEdge.
              </Heading>
              <Paragraph style={{ width: '90%', maxWidth: '100%' }}>
                The need for upskilling, reskilling and future-proofing your
                workforce has never been greater. As business moves even further
                into the digital age, roles will continue to evolve. The skills
                needed to meet these new demands will change. And the way people
                learn these skills should evolve, too.
              </Paragraph>
              <Paragraph
                style={{ marginTop: '20px', width: '90%', maxWidth: '100%' }}
              >
                From identifying critical skill gaps in your business to helping
                you build your workforce of the future, ProEdge delivers a
                one‑of‑a‑kind upskilling experience.
              </Paragraph>
              <Paragraph
                style={{ marginTop: '20px', width: '90%', maxWidth: '100%' }}
              ><Anchor href="/demo" target="_blank" style={{textDecoration: 'none', paddingBottom: '1px', borderBottom: '1px dotted #D04A02'}}>Would you like to request a demo of ProEdge?</Anchor></Paragraph>
            </Box>
          </FooterContainer>
      ) : null } 
      </>
        )
    }}
    </ResponsiveContext.Consumer>
    </>
  );
}

export default QuizApp;
