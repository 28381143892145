import React, { useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { ButtonContainer, Button } from '../Button';
import { FieldFactory } from './Inputs';
import SocialIcons, { SocialItemsData } from '../SocialIcons';
import Link from '../Link';
import RichText from '../RichText';

const FormMessage = ({
  wasSuccessful,
  successTitle,
  successSubtitle,
  failureTitle,
  failureSubtitle,
}) => {
  return (
    <div className="form__message">
      <div className="form__message-content">
        <p className="form__message-title">
          {wasSuccessful ? successTitle : failureTitle}
        </p>
        <p className="form__message-subtitle">
          {wasSuccessful ? successSubtitle : failureSubtitle}
        </p>
        <p className="form__message-footer">
          Need help? Have a question? <Link href="https://proedge.pwc.com/contact">Contact us</Link>.
        </p>
      </div>

      <div className="form__message-social">
        <p>Let's connect</p>
        <SocialIcons
          items={SocialItemsData.filter((item) =>
            ['twitter', 'linkedin'].includes(item.slug),
          )}
          theme="light"
        />
      </div>
    </div>
  );
};

FormMessage.defaultProps = {
  wasSuccessful: true,
  successTitle: 'Thank you!',
  failureTitle: "We're sorry",
  successSubtitle:
    'We look forward to showing you all the ways ProEdge can revolutionize your workforce.',
  failureSubtitle:
    'Something went wrong. Refresh the page and try again or come back later.',
};

const FormHeader = ({ title, subtitle }) => {
  return (
    <div className="form__header">
      <p className="form__title">{title}</p>
      <p className="form__subtitle">{subtitle}</p>
    </div>
  );
};

const encodeFormData = (values) => {
  return Object.keys(values)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(values[key]),
    )
    .join('&');
};

const Form = (props) => {
  const {
    url,
    formTitle,
    formSubtitle,
    fields,
    legal,
    onSubmitSuccess,
    onSubmitFail,
    onSubmitError,
    eloquaSiteId,
    eloquaFormName,
  } = props;

  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
  });

  const eloquaHiddenFields = {
    elqFormName: eloquaFormName,
    elqSiteId: eloquaSiteId,
    elqCampaignId: null,
    elqCustomerGUID: '',
    elqCookieWrite: 0,
  };

  const initialValues = Object.fromEntries(
    fields.map(({ name, initialValue }) => [
      name,
      initialValue ? initialValue : '',
    ]),
  );

  const selectErrorMessage = 'Please select a valid option';

  const schemaFactory = ({
    type,
    required,
    options,
    conditionalOptions,
    conditionalFieldName,
    conditional,
  }) => {
    let schema = '';
    // First, assume everything is a string.
    let inputType = Yup.string();
    schema = inputType;

    // Next, if it's a select or radio make sure it only includes its options
    if (type === 'email') {
      schema = schema.email('Please enter a valid email address').matches(
        /\@(?!(me|mac|abc|qq|icloud|gmail|googlemail|hotmail|live|msn|outlook|yahoo|ymail|aol)\.)/, 'Please enter a business email address'
      )
    }

    // If it has options, make sure it only allows those options
    if (options) {
      schema = schema.oneOf(options, selectErrorMessage);
    }

    // if it's a conditional field
    // set up the schema to check the related field and require it
    // otherwise, require it normally.
    if (conditional) {
      schema = schema.when(conditionalFieldName, {
        is: (a) => conditionalOptions.includes(a),
        then: Yup.string().required(),
      });
    } else if (required) {
      return (schema = schema.required('Required'));
    }

    // return our completed schema
    return schema;
  };

  const validationSchemaShape = Object.fromEntries(
    fields.map((field) => {
      return [field.name, schemaFactory(field)];
    }),
  );

  // Submit handler
  const handleSubmit = (values, { setSubmitting }) => {
    const encodedValues = encodeFormData(values);
    setSubmitting(true);

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodedValues,
    })
      .then((res) => {
        if (res.ok) {
          setFormStatus({ submitted: true, success: true });
          onSubmitSuccess();
        } else {
          setFormStatus({ submitted: true, success: false });
          onSubmitFail();
        }
      })
      .catch((err) => {
        setFormStatus({ submitted: true, success: false });
        onSubmitError(err);
      });
  };

  const formikProps = {
    validationSchema: Yup.object().shape(validationSchemaShape),
    initialValues: { ...initialValues, ...eloquaHiddenFields },
    onSubmit: handleSubmit,
  };

  return (
    <div className="form">
      {!onSubmitSuccess && formStatus.submitted ? (
        <FormMessage wasSuccessful={formStatus.success} />
      ) : (
        <>
          <div className="form__body">
            <Formik {...formikProps}>
              {(props) => {
                const { isSubmitting, values } = props;
                const submitDisabled = !(props.isValid && props.dirty);
                return (
                  <FormikForm
                    name={eloquaFormName}
                    className={`form__element ${
                      isSubmitting ? 'form__element--submitting' : ''
                    }`}
                  >
                    <div className="form__inputs">
                      {fields.map((field, index) => {
                        let isDisabled =
                          field.conditional &&
                          !field.conditionalOptions.includes(
                            values[field.conditionalFieldName],
                          );

                        return (
                          <FieldFactory
                            key={index}
                            disabled={isDisabled}
                            {...field}
                          />
                        );
                      })}
                    </div>

                    {legal?.json && (
                      <div className="form__legal">
                        <RichText source={legal.json} />
                      </div>
                    )}
                    <ButtonContainer align="center">
                      <Button
                        disabled={submitDisabled || isSubmitting}
                        type="submit"
                      >
                        {isSubmitting ? 'Submitting...' : 'See my results'}
                      </Button>
                    </ButtonContainer>
                  </FormikForm>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};
Form.defaultProps = {
  fields: [],
  onSubmitSuccess: () => null,
  onSubmitError: () => null,
  onSubmitFail: () => null,
};

export { Form, encodeFormData };
