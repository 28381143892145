import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Form } from '../../../components/FormApp';
import FormSection from '../../../components/FormApp/FormSection';
import { useCookie } from 'react-use';

const QuizForm = ({ formSection, onSubmitSuccess }) => {

  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);


  const handleSubmit = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
    typeof onSubmitSuccess === 'function' && onSubmitSuccess();
  }
  return (
      <FormSection>
        <Form {...formSection} onSubmitSuccess={() => handleSubmit()} />
      </FormSection>
  )
}

export default QuizForm;
