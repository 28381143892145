import React, { createContext, useReducer, useContext } from "react";

function formReducer(state, action) {
  switch (action.type) {
    case "DISRUPTION_SCORE_CHANGE":
      return { ...state, disruptionScore: action.payload };
    case "WORKFORCE_SCORE_CHANGE":
      return { ...state, workforceScore: action.payload };
    case "CORPORATECULTURE_SCORE_CHANGE":
      return { ...state, corporateCultureScore: action.payload };
    case "DIGITALTRANSFORMATION_SCORE_CHANGE":
      return { ...state, digitalTransformationScore: action.payload };
    case "MULTIGEN_SCORE_CHANGE":
      return { ...state, multiGenScore: action.payload };
    case "UPSKILLING_SCORE_CHANGE":
      return { ...state, upskillingScore: action.payload };
    case 'SHOULD_ALLOW_NEXT':
      return { ...state, shouldAllowNext: action.payload };
    case 'SHOULD_RENDER_STEP_INFO':
      return { ...state, shouldRenderStepInfo: action.payload };
    case "SUBMIT":
      return { ...state, isSubmitLoading: true };
    case "SUBMISSION_RECEIVED":
      return { ...state, isSubmitLoading: false, isSubmissionReceived: true };
    default:
      throw new Error();
  }
}

const QuizAppContext = createContext();

const initialState = {
  disruptionScore: '',
  workforceScore: '',
  corporateCultureScore: '',
  digitalTransformationScore: '',
  multiGenScore: '',
  upskillingScore: ''
};

export const QuizAppProvider = function({ children }) {
  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <QuizAppContext.Provider value={{ state, dispatch }}>
      {children}
    </QuizAppContext.Provider>
  );
};

export function useQuizAppState() {
  const context = useContext(QuizAppContext);

  if (context === undefined) {
    throw new Error(
      "useQuizAppState must be used within a QuizAppProvider"
    );
  }

  return context;
}
