import React, { useEffect } from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading, Text  } from 'grommet';
import { useQuizAppState } from "../QuizAppContext";

export function WorkforceTransformationStep() {
  const {
    state: { workforceScore },
    dispatch,
  } = useQuizAppState();

  const isValid = workforceScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'quizapp-step-workforce' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [workforceScore])

  return (
    <>
      <Heading level="2" color="#ffb600">2. Workforce Transformation</Heading>
      <Heading level="4" margin={{ vertical: '30px' }}>Evaluate the following statement:</Heading>
      <Box direction="row-responsive" justify="between">
        <Box basis='40%'>
          <Box>
            <Paragraph style={{ maxWidth: 'inherit' }}>As we evaluate the future needs for our workforce, we’re struggling to identify key roles to retain and the new skills we’ll need to develop across departments and jobs.</Paragraph>
            <Form style={{ margin: '20px 0', maxWidth: 'inherit' }}>
              <RadioButtonGroup
                style={{lineHeight: '1.8'}}
                name="workforceScore"
                options={[
                  { label: 'Yes, this is mostly true and we are struggling to build out our talent requirements in the long term.', value: '1' },
                  { label: 'This is partially true; we know what roles we’ll need but are struggling with skills development.', value: '3' },
                  { label: 'No, workforce planning is well defined for roles and skills needed in the future.', value: '5' }
                ]}
                value={workforceScore}
                gap="medium"
                onChange={(event) => {
                  const value = event.target.value;
                  dispatch({ type: "WORKFORCE_SCORE_CHANGE", payload: value })
                }}
              />
              </Form>
            </Box>
          </Box>
          <Box basis='55%'>
            <Box 
              style={{
              padding: '30px',
              border: '6px solid #ffb600',
              opacity: workforceScore !== '' ? 1 : 0,
              transition: 'opacity 400ms',
              color: '#ffb600'
              }}>
              <Paragraph style={{fontSize: '18px', lineHeight: '28px', paddingBottom:'20px', maxWidth: '600px',}}>PwC’s 23rd Annual Global CEO Survey uncovered serious concerns about skills gaps in the enterprise. 79% of CEOs are concerned about the availability of key skills, yet only 18% have made significant progress in establishing comprehensive upskilling programs.</Paragraph>
              <Paragraph style={{fontSize: '18px', lineHeight: '28px', maxWidth: '600px', paddingBottom:'20px'}}><strong>Companies need to be able to:</strong></Paragraph>
              <ul><li>Assess organizational skills and identify critical skill gaps</li>
                  <li>Forecast skills for future success based on industry benchmarks and marketplace insights</li>
                  <li>Launch individual learning plans focused on the skills that align to organizational goals</li></ul>
            </Box>
        </Box>
    </Box>
    </>
  );
}

export default WorkforceTransformationStep;
