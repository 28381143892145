import React from 'react';
import {Heading, Text, Image, Paragraph } from 'grommet';
import styled from 'styled-components';

const ExplainContainer = styled.div`
  border: 4px solid #FFFFFF;
  padding: 40px 20px;
  @media(min-width: 768px) {
    padding: 40px
  }
`;

const ImgContainer = styled.div`
  max-width: 240px;
  width: 100%;
  padding: 0 0 20px;
`; 

const ScoreText = styled(Text)`
  padding: 0 0 10px;
  margin: 10px 0 30px 0;
  display: inline-block;
  color: #ffb600;
  font-size: 4em;
  font-weight: 600;
`;

const scoreImg = (totalScore) => {
  let imgSrc;
  if ( totalScore <= 14  ) {
    imgSrc = 'https://images.ctfassets.net/l07wyut35yzl/4P0ocOZq2oV7z0YC9daz9f/13ec13468f9dea5123364285c86381a1/img-you-deserve-better2.svg';
  } else if ( totalScore >= 15 && totalScore <= 23 ) {
    imgSrc = 'https://images.ctfassets.net/l07wyut35yzl/6WlT6JRKvoNGfphXK1hZSQ/63759c73762a26ceb263fae4e2f7c263/img-opportunity-for-change2.svg';
  } else {
    imgSrc = 'https://images.ctfassets.net/l07wyut35yzl/4BGmzt8ElD3rE63LU3rgzx/60195598dca9600a9691fe32dae56495/img-on-the-right-path2.svg';
  };
  return imgSrc
}

const explainResult = (totalScore) => {
  let result;
  if ( totalScore <= 14 ) {
    result = 'You have an upskilling opportunity!';
  } else if ( totalScore >= 15 && totalScore <= 23 ) {
    result = 'You scored above average in relation to your peers.';
  } else {
    result = 'You are an industry leader!';
  };
  return result
}

const explainParagraph = (totalScore) => {
  let explainText;
  if ( totalScore <= 14 ) {
    explainText = 'Your answers indicate a clear opportunity to benefit from digital upskilling. The development of skills in areas such as automation, AI, data visualization, agile project management and design thinking will help your workforce and your company reach goals and prepare for disruption. You would also benefit from an evaluation of the skills of your workforce and guidance on the roles you’ll need in the future.';
  } else if ( totalScore >= 15 && totalScore <= 23 ) {
    explainText = 'You handle change and clearly focus on culture and skills, but you could still benefit from digital upskilling. The development of skills in areas such as automation, AI, data visualization, agile project management and design thinking will help your workforce and your company reach goals and prepare for disruption. You might also benefit from an evaluation of the skills of your workforce and guidance on the roles you’ll need in the future.';
  } else {
    explainText = 'You and your workforce have clearly invested in developing the skills you will need in the future, and you’ve handled digital transformation very well. But how are you going to keep your track record of success? Ongoing development of skills in areas such as automation, AI, data visualization, agile project management and design thinking will help your workforce and your company reach goals and prepare for disruption. You might also benefit from an evaluation of the skills of your workforce and guidance on the roles you’ll need in the future.';
  };
  return explainText
}

export function ScoreExplain({ totalScore }) {
  return (
    <ExplainContainer>
      <ScoreText>{ totalScore } <Text style={{fontSize: '24px'}}>out of 30</Text> </ScoreText>
      <ImgContainer>
          <Image
            src={scoreImg(totalScore)}
            style={{
              width: '100%'
            }}
          />
        </ImgContainer>
      <Heading level="3" style={{maxWidth: '100%', fontWeight: 'bold'}} color="#ffb600">
        {explainResult(totalScore)}
      </Heading>
      <Paragraph
        style={{
          padding: '30px 0 10px',
          maxWidth: '96%'
        }}
      >{explainParagraph(totalScore)}
      </Paragraph>
    </ExplainContainer>
  );
}
