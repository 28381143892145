import React, { useEffect } from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading, Text  } from 'grommet';
import { useQuizAppState } from "../QuizAppContext";

export function UpskillingStep() {
  const {
    state: { upskillingScore },
    dispatch,
  } = useQuizAppState();

  const isValid = upskillingScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'quizapp-step-upskilling' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [upskillingScore])

  return (
    <>
      <Heading level="2" color="#ffb600">6. Upskilling</Heading>
      <Heading level="4" margin={{ vertical: '30px' }}>Evaluate the following statement:</Heading>
      <Box direction="row-responsive" justify="between">
        <Box basis='45%'>
          <Box>
            <Paragraph style={{ maxWidth: 'inherit' }}>We’ve added learning programs as a perk for our employees, but have struggled to measure success in these programs. I’m not convinced the training they receive will <strong>benefit the company as well as the learner</strong>.</Paragraph>
            <Form style={{ margin: '20px 0', maxWidth: 'inherit' }}>
              <RadioButtonGroup
                style={{lineHeight: '1.8'}}
                name="upskillingScore"
                options={[
                  { label: 'Yes, this is mostly true. Our training programs haven’t been embraced by our employees, and we have trouble measuring the ROI of our training.', value: '1', id: 'one' },
                  { label: 'This is partially true; we have good participation in our programs but the results are difficult to quantify.', value: '3', id: 'two' },
                  { label: 'No, our training programs are widely used across all departments, and we’re very happy with the results to date.', value: '5', id: 'three' }
                ]}
                value={upskillingScore}
                gap="medium"
                onChange={(event) => {
                  const value = event.target.value;
                  dispatch({ type: "UPSKILLING_SCORE_CHANGE", payload: value })
                }}
              />
              </Form>
            </Box>
          </Box>
          <Box basis='45%'>
            <Box 
              style={{
              padding: '30px',
              border: '6px solid #ffb600',
              opacity: upskillingScore !== '' ? 1 : 0,
              transition: 'opacity 400ms'
              }}>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', paddingBottom:'20px',maxWidth: '600px',}}>Convincing leadership to invest in learning initiatives is often hindered by success metrics centered on usage rather than outcomes from the training or upskilling.</Paragraph>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', maxWidth: '600px',paddingBottom:'20px'}}>Focus on upskilling initiatives teaching the digital skills that will help to grow the business or increase productivity. Make them accessible to all, and deliver them in fun and easily consumable methods across platforms.</Paragraph>
            </Box>
        </Box>
      </Box>
    </>
  );
}

export default UpskillingStep;
