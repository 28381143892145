import React, { useEffect } from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading, Text  } from 'grommet';
import { useQuizAppState } from "../QuizAppContext";

export function MultiGenWorkforceStep() {
  const {
    state: { multiGenScore },
    dispatch,
  } = useQuizAppState();

  const isValid = multiGenScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'quizapp-step-multigenworkforce' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [multiGenScore])

  return (
    <>
      <Heading level="2" color="#ffb600">5. Multi-generational Workforce</Heading>
      <Heading level="4" margin={{ vertical: '30px' }}>Evaluate the following statement:</Heading>
      <Box direction="row-responsive" justify="between">
        <Box basis='45%'>
          <Box>
            <Paragraph style={{ maxWidth: 'inherit' }}>We have a multigenerational workforce and need to offer digital skill development that will match current employee skill levels and relevant growth paths that ensure long-term retention.</Paragraph>
            <Form style={{ margin: '20px 0', maxWidth: 'inherit' }}>
              <RadioButtonGroup
                style={{lineHeight: '1.8'}}
                name="multiGenScore"
                options={[
                  { label: 'Yes, this is mostly true and workforce skills assessments show a wide range of gaps.', value: '1', id: 'one' },
                  { label: 'This is partially true; we have some concerns about matching skills to staff experience.', value: '3', id: 'two' },
                  { label: 'No, we feel very comfortable with the skills development of every employee regardless of role or experience.', value: '5', id: 'three' }
                ]}
                value={multiGenScore}
                gap="medium"
                onChange={(event) => {
                  const value = event.target.value;
                  dispatch({ type: "MULTIGEN_SCORE_CHANGE", payload: value })
                }}
              />
              </Form>
            </Box>
          </Box>
          <Box basis='45%'>
            <Box 
              style={{
              padding: '30px',
              border: '6px solid #ffb600',
              opacity: multiGenScore !== '' ? 1 : 0,
              transition: 'opacity 400ms'
              }}>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', paddingBottom:'20px',maxWidth: '600px',}}>Different employees often have different needs when it comes to digital upskilling. Perhaps your more experienced employees struggle with the technological aspects of your digital transformation, while newer employees lack the softer skills to execute or present new ideas to leadership.</Paragraph>
              <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', maxWidth: '600px',paddingBottom:'20px'}}>True digital upskilling accounts for individual needs and involves personalized educational plans that align learner needs with corporate goals.</Paragraph>
            </Box>
        </Box>
      </Box>
    </>
  );
}

export default MultiGenWorkforceStep;
