import React, { useEffect } from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading, Text  } from 'grommet';
import { useQuizAppState } from "../QuizAppContext";

export function CorporateCultureStep() {
  const {
    state: { corporateCultureScore },
    dispatch,
  } = useQuizAppState();

  const isValid = corporateCultureScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'quizapp-step-corporateculture' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [corporateCultureScore])

  return (
    <>
      <Heading level="2" color="#ffb600">3. Corporate Culture</Heading>
      <Heading level="4" margin={{ vertical: '30px' }}>Evaluate the following statement:</Heading>
      <Box direction="row-responsive" justify="between">
        <Box basis='45%'>
          <Box>
            <Paragraph style={{ maxWidth: 'inherit' }}>Our team doesn’t have the knowledge or incentives to raise their hand and think about how to perform work differently, improve productivity, leverage new technology or manage projects more effectively.</Paragraph>
            <Form style={{ margin: '20px 0 40px', maxWidth: 'inherit' }}>
            <RadioButtonGroup
              style={{lineHeight: '1.8'}}
              name="corporateCultureScore"
              options={[
                { label: 'Yes, this is mostly true and we’re looking for ways to create a culture focused on innovation.', value: '1', id: 'one' },
                { label: 'This is partially true; we sometimes struggle with innovation but have a strong corporate culture.', value: '3', id: 'two' },
                { label: 'No, our corporate culture embraces learning and has driven innovation from the ground up.', value: '5', id: 'three' }
              ]}
              gap="medium"
              value={corporateCultureScore}
              onChange={(event) => {
                dispatch({ type: "CORPORATECULTURE_SCORE_CHANGE", payload: event.target.value  })
              }}
            />
              </Form>
            </Box>
          </Box>
          <Box basis='45%'>
          <Box 
            style={{
            padding: '30px',
            border: '6px solid #ffb600',
            opacity: corporateCultureScore !== '' ? 1 : 0,
            transition: 'opacity 400ms'
            }}>
            <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', paddingBottom:'20px',maxWidth: '600px',}}>Companies want to give their people the tools to help achieve repeatable, sustainable change and growth but sometimes struggle with creating a culture of learning and innovation.</Paragraph>
            <Paragraph color="#ffb600" style={{fontSize: '18px', lineHeight: '28px', maxWidth: '600px',paddingBottom:'20px'}}>Investing in digital upskilling is a vital step, but moves beyond curriculum or certifications. <strong>It requires an environment that incorporates education and innovation</strong> into workflows and in ways that adapt to different needs and learning preferences.</Paragraph>
          </Box>
        </Box>
    </Box>
    </>
  );
}

export default CorporateCultureStep;
